import axios from "axios";
import { jwtDecode } from "jwt-decode";

const checkAuth = async () => {
  const TOKEN = localStorage.getItem("token");
  const PUBLIC_ROUTES = ["login"];

  const isPublicPage = PUBLIC_ROUTES.some((r) => window.location.href.includes(r));

  if (!TOKEN && !isPublicPage) {
    return "AUTH_REQUIRED";
  } else {
    axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

    try {
      const response = await axios.get('/api/validate-token');
      
      if (response.status === 200) {
        const decodedToken = jwtDecode(TOKEN);
        const roles = decodedToken.authorities || [];
        
        const isAdmin = roles.includes("ADMIN") || roles.includes("SUPERADMIN");
        const isTechnician = roles.includes("TECHNICIEN");
        
        if (isAdmin) {
          return { status: "AUTHORIZED", defaultLink: "/app/utilisateurs" };
        } else if (isTechnician) {
          return { status: "AUTHORIZED", defaultLink: "/app/services" };
        } else {
          return "AUTH_REQUIRED";
        }
      } else {
        return "AUTH_REQUIRED";
      }
    } catch (error) {
      return "AUTH_REQUIRED";
    }
  }
};

export default checkAuth;
